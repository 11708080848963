import React, { useState, useEffect } from 'react';
import './App.css'; 
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import VideoPlayer from './VideoPlayer';
import { UserCircleIcon } from '@heroicons/react/outline';
import axios from 'axios';

const BasesHome = () => {
  const navigate = useNavigate();

  return (
    <div
      className="min-h-screen flex flex-col pt-16 relative"
      style={{
        background: 'linear-gradient(45deg, #FFEB3B, #FFC107)',
      }}
    >
      {/* Header Section */}
      <Header />

      {/* Title Section */}
      <div className="w-full text-center flex flex-col items-center justify-center flex-grow mt-8">
        <h1
          className="text-white font-['Barlow'] font-bold drop-shadow-2xl"
          style={{
            fontSize: 'min(25vw, 50vh)',
            lineHeight: '0.8',
            letterSpacing: '-0.02em',
            filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2))',
          }}
        >
          bases
        </h1>
        <div className='mt-4'>
          <CyclingText />
        </div>
        <InputSection />
      </div>

      {/* Divider Section */}
      <div className="mt-10">
        <hr className="border-t-2 border-white/0" />
      </div>

      {/* About Section */}
      <div className="flex items-center justify-center p-6 mt-8">
        <div className="w-full flex flex-row items-center gap-16">
          <div className="w-full bg-[#ECBD00] backdrop-blur-sm border-4 border-white shadow-xl rounded-xl p-8 space-y-2 max-w-[600px] min-h-[400px] ml-0 mr-0">
            <h1 className="text-4xl font-bold text-white font-barlow mb-3">
              <i><b>bases</b></i> is your personal highlight helper.
            </h1>
            <p className="text-white/90 font-barlow mb-1">
              whether you're looking for top-rated clips or understanding your favorite baseball moments,
            </p>
            <p className="text-1xl text-white font-bold font-barlow mb-1">we've got you covered.</p>
            <p className="text-2xl text-white font-bold font-barlow mb-2">here's how it works:</p>
            <div className="space-y-2">
              <div className="flex gap-2 items-start">
                <span className="text-white font-barlow">1.</span>
                <p className="text-white font-barlow">
                  paste a link of a highlight clip <span className="text-white/75">(max 2min)</span>
                </p>
              </div>
              <div className="flex gap-2 items-start">
                <span className="text-white font-barlow">2.</span>
                <p className="text-white font-barlow">wait for us while we process & analyze key moments.</p>
              </div>
              <div className="flex gap-2 items-start">
                <span className="text-white font-barlow">3.</span>
                <p className="text-white font-barlow">enjoy!</p>
              </div>
            </div>
            <button
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="bg-[#F1C100] text-white font-bold drop-shadow-lg hover:bg-[#F1C100]/90 font-barlow px-6 py-3 rounded-full transition duration-300 transform hover:scale-105"
            >
              Get Started
            </button>
          </div>
          <div style={{ width: '80%', height: '100%' }} className="flex-grow flex justify-center ml-0 mr-0">
            <img
              src="/Frame 9 (2).png"
              alt="Baseball game interface"
              className="rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CyclingText = () => {
  const phrases = [
    "your personalized highlight helper.",
    "your personalized baseball buddy.",
    "your go-to baseball assistant.",
    "the ultimate highlight analyzer.",
    "your baseball moments, redefined."
  ];

  const [currentText, setCurrentText] = useState("");
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;

    if (!isDeleting && currentText === phrases[index]) {
      // Finished typing, wait before deleting
      timer = setTimeout(() => setIsDeleting(true), 1500);
    } else if (isDeleting && currentText === "") {
      // Finished deleting, move to next phrase
      setIsDeleting(false);
      setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    } else {
      // Typing or deleting
      timer = setTimeout(() => {
        setCurrentText(prev => 
          isDeleting 
            ? prev.slice(0, -1) 
            : phrases[index].slice(0, prev.length + 1)
        );
      }, isDeleting ? 50 : 80);
    }

    return () => clearTimeout(timer);
  }, [currentText, index, isDeleting, phrases]);

  return (
    <div className="h-[3em] flex items-center justify-center">
      <p className="text-white/80 text-[min(2.5vw,24px)]" style={{ filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2))' }}>
        {currentText}
        <span className="animate-pulse ml-1 text-white/100">|</span>
      </p>
    </div>
  );
};

const Header = () => (
  <header className="w-full flex justify-end items-center p-4 fixed top-0 left-0 bg-white/10 backdrop-blur-sm shadow-sm z-10">
    <nav className="flex items-center space-x-6">
      <a href="/top-rated" className="text-white text-sm font-bold hover:underline" style={{ fontSize: '1rem' }}>
        Top Rated
      </a>
      <a href="/saved" className="text-white text-sm font-bold hover:underline" style={{ fontSize: '1rem' }}>
        Saved
      </a>
      <a href="/login" className="text-white text-sm font-bold hover:underline" style={{ fontSize: '1rem' }}>
        <UserCircleIcon className="w-6 h-6 text-white" />
      </a>
    </nav>
  </header>
);

const InputSection = () => {
  const [videoLink, setVideoLink] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();  // Add useNavigate hook

  const handleSubmit = async () => {
    if (!videoLink.trim()) {
      alert("Please enter a video link");
      return;
    }

    setLoading(true);
    
    // Introduce a small delay before navigating to the loading screen
    setTimeout(async () => {
      try {
        
        // Then start the analysis process
        const response = await axios.post('https://pranav-387216.nn.r.appspot.com/analyze', {
          video_url: videoLink,
        });
    
        if (response.data && response.data.analysis) {
          // Store the analysis in localStorage
          localStorage.setItem('videoAnalysis', JSON.stringify(response.data.analysis));
          // Navigate to video player after analysis is complete
          navigate('/video');
        } else {
          throw new Error("No analysis data received");
        }
    
      } catch (error) {
        console.error("Error analyzing video:", error);
        alert("Failed to analyze the video. Please try again.");
        navigate('/'); // Go back to home on error
        setLoading(false);
      }
    }, 1000);  // Delay the navigation by 1.5 seconds (adjustable)
  };  

  return (
    <div className="mt-12 max-w-xl mx-auto w-full">
      <div className="relative">
        <input
          type="text"
          placeholder="Insert video link here"
          value={videoLink}
          onChange={(e) => setVideoLink(e.target.value)}
          className="w-full px-4 py-3 rounded-lg 
                    bg-white/20 backdrop-blur
                    text-white placeholder-white/90
                    border-2 border-white/30
                    focus:outline-none focus:border-white/90
                    pr-12"
        />
        <button
          onClick={handleSubmit}
          disabled={loading}
          className={`absolute right-2 top-1/2 -translate-y-1/2 
                     text-yellow px-3 py-1 rounded-full
                     transition-colors
                     ${loading ? 'bg-white/30 cursor-not-allowed' : 'bg-yellow/10 hover:bg-white/50'}`}
        >
          {loading ? "Processing..." : "➔"}
        </button>
      </div>
    </div>
  );
};


const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<BasesHome />} />
      <Route path="/loading" element={<LoadingScreen />} />
      <Route path="/video" element={<VideoPlayer />} />
    </Routes>
  </Router>
);

export default App;
