import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bookmark, Clock, Share } from 'lucide-react';
import axios from 'axios';

const VideoPlayer = () => {
  const navigate = useNavigate(); // Use navigate hook
  const [timelineMarkers, setTimelineMarkers] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [videoSrc, setVideoSrc] = useState(null); // Initially null
  const [analysisData, setAnalysisData] = useState(null);

  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const timelineRef = useRef(null);
  const isDragging = useRef(false);

  useEffect(() => {
    axios
      .get(`https://storage.googleapis.com/pranav-387216.appspot.com/analysis/new_analysis.json?${new Date().getTime()}`)
      .then((response) => {
        setAnalysis(response.data.analysis);
        setTimelineMarkers(response.data.analysis.map((entry) => ({
          time: entry.time,
          position: entry.position,
          color: entry.color,
        })));
        
        // Set video source only after analysis is ready
        setVideoSrc("https://storage.googleapis.com/pranav-387216.appspot.com/videos/video.mp4");
      })
      .catch((error) => console.error("Error loading analysis data:", error));
  }, []);

  useEffect(() => {
    axios.get(analysisData)
      .then((response) => {
        const data = response.data;
        setTimelineMarkers(data.analysis.map((entry) => ({
          time: entry.time,
          position: entry.position,
          color: entry.color,
        })));
        setAnalysis(data.analysis);
      })
      .catch((error) => console.error("Error loading analysis data:", error));
  }, [analysisData]);
  

  useEffect(() => {
    let animationFrameId;

    const handleTimeUpdate = () => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        const duration = videoRef.current.duration;
        if (!isNaN(duration) && duration > 0) {
          animationFrameId = requestAnimationFrame(() => {
            setProgress((currentTime / duration) * 100);
          });
        }
      }
    };

    const video = videoRef.current;
    if (video) {
      video.addEventListener("timeupdate", handleTimeUpdate);
    }

    const handleSpacebar = (e) => {
      if (e.key === ' ') {
        e.preventDefault();
        if (videoRef.current.paused) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    };

    window.addEventListener('keydown', handleSpacebar);

    return () => {
      cancelAnimationFrame(animationFrameId);
      if (video) {
        video.removeEventListener("timeupdate", handleTimeUpdate);
      }
      window.removeEventListener('keydown', handleSpacebar);
    };
  }, []);

  const handleTimelineClick = (e) => {
    const timeline = e.currentTarget;
    const rect = timeline.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const newProgress = (x / rect.width) * 100;
    setProgress(Math.min(100, Math.max(0, newProgress)));

    if (videoRef.current) {
      const duration = videoRef.current.duration;
      if (!isNaN(duration) && duration > 0) {
        videoRef.current.currentTime = (newProgress / 100) * duration;
      }
    }
  };

  const handleMouseDown = (e) => {
    isDragging.current = true;
    const timeline = timelineRef.current;
    const rect = timeline.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const newProgress = (x / rect.width) * 100;
    setProgress(Math.min(100, Math.max(0, newProgress)));

    if (videoRef.current) {
      const duration = videoRef.current.duration;
      if (!isNaN(duration) && duration > 0) {
        videoRef.current.currentTime = (newProgress / 100) * duration;
      }
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging.current) {
      const timeline = timelineRef.current;
      const rect = timeline.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const newProgress = (x / rect.width) * 100;
      setProgress(Math.min(100, Math.max(0, newProgress)));

      if (videoRef.current) {
        const duration = videoRef.current.duration;
        if (!isNaN(duration) && duration > 0) {
          videoRef.current.currentTime = (newProgress / 100) * duration;
        }
      }
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  return (
    <div className="min-h-screen bg-yellow-400">
      <div className="flex gap-6 p-6 max-w-6xl mx-auto">
        <div className="w-2/3">
          <div className="rounded-lg overflow-hidden shadow-xl">
            <div className="relative bg-black aspect-video">
              <video
                ref={videoRef}
                className="w-full h-full"
                controls={false}
                src={videoSrc}
              />
            </div>

            <div className="bg-white rounded-b-lg p-4">
              <div className="mb-4 relative">
                <div
                  ref={timelineRef}
                  className="h-1.5 bg-gray-200 rounded-full cursor-pointer relative"
                  onClick={handleTimelineClick}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  <div
                    className="absolute h-full bg-yellow-400 rounded-full"
                    style={{ width: `${progress}%` }}
                  />
                  {timelineMarkers.map((marker, index) => (
                    <div
                      key={index}
                      className={`absolute w-3 h-3 ${marker.color || "bg-gray-500"} rounded-full -top-[3px] cursor-pointer transform -translate-x-1/2 hover:scale-125 transition-transform`}
                      style={{ left: `${marker.position}%` }}
                      title={marker.time}
                    />
                  ))}
                </div>
              </div>

              <div className="flex gap-4">
                <button className="p-2 bg-yellow-400 hover:bg-yellow-200 rounded-full">
                  <Bookmark className="w-5 h-5" />
                </button>
                <button className="p-2 bg-yellow-400 hover:bg-yellow-200 rounded-full">
                  <Clock className="w-5 h-5" />
                </button>
                <button className="p-2 bg-yellow-400 hover:bg-yellow-200 rounded-full">
                  <Share className="w-5 h-5" />
                </button>
                <button
                  onClick={() => navigate("/")}
                  className="ml-auto bg-yellow-500 font-bold text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-yellow-600"
                >
                  <img src="/Group 9.png" alt="Custom Icon" className="w-5 h-5 rounded-full custom-shadow" />
                  New Analysis
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/3 flex flex-col justify-center items-center">
          <div className="bg-white rounded-lg p-4 flex-grow flex flex-col overflow-y-auto space-y-6 max-h-[calc(100vh-6rem)] shadow-2xl">
            {console.log(analysis)}
            {analysis.map((entry, index) => (
              <div key={index} className="space-y-2">
                <div className="flex items-center gap-2">
                  <div className={`w-3 h-3 ${entry.color} rounded-full`} />
                  <span className="font-bold text-black">{entry.time}</span>
                </div>
                <p className="text-gray-700">
                  {entry.explanation}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;