import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoadingScreen.css';

function LoadingScreen() {
  const [progress, setProgress] = useState(1);
  const [imageURL, setImageURL] = useState("/shoheiexample.jpg");
  const [animationComplete, setAnimationComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            setAnimationComplete(true);
          }, 500);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 10);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (animationComplete) {
      setTimeout(() => {
        navigate('/video');
      }, 1000);
    }
  }, [animationComplete, navigate]);

  return (
    <div className="h-screen bg-yellow-400 flex items-center justify-center">
      <div className={`wrapper ${animationComplete ? 'animating' : ''}`}>
        {imageURL ? (
          <>
            {!animationComplete && (
              <>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-center z-10">
                  <div className="text-8xl font-bold text-white">{progress}%</div>
                </div>

                <div className="absolute bottom-0 w-full bg-yellow-500 h-2">
                  <div
                    className="h-full bg-orange-500"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </>
            )}

            <div className={`imageContainer ${animationComplete ? 'animating' : ''}`}>
              <div className="imageOverlay"></div>
              <img
                src={imageURL}
                alt="Loading"
                className={animationComplete ? 'animateToVideoPlayer' : ''}
              />
            </div>
          </>
        ) : (
          <div className="relative text-center z-10">
            <div className="text-6xl font-bold text-white">{progress}%</div>
            <div className="mt-2 text-xl font-medium text-white">analysis in progress...</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoadingScreen;